<template>
  <v-dialog
    v-model="dialog"
    :close-on-content-click="false"
    :activator="activator"
    max-width="400"
  >
    <v-card aut-event-display>
      <DialogTitle aut-name @close="$emit('close')" :content="event.name" />
      <v-card-subtitle aut-time v-html="eventTime"></v-card-subtitle>
      <div class="event_edit">
        <v-btn
          v-if="isEditMode"
          icon
          small
          @click="$emit('edit', event)"
          aut-edit
        >
          <v-icon dense>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="isEditMode" icon small @click="$emit('remove', event)">
          <v-icon dense>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <v-card-text
        class="pt-4"
        v-html="event.details || 'No details available'"
        aut-details
      >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { isSameDay, parseISO } from "date-fns";
export default {
  name: "EventDisplay",
  mixins: [dialogMixin],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    event: Object,
    activator: {},
    mode: String,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(dialog) {
        this.$emit("change", dialog);
      },
    },
    isEditMode() {
      return this.mode == "input";
    },
    eventTime() {
      const start = this.event?.start || "";
      const end = this.event?.end || "";
      const result = this.getFormattedTime(start, end);
      return result;
    },
  },
  methods: {
    getFormattedTime(start, end) {
      const formatDate = this.$options.filters.date;
      const startDate = parseISO(start);
      const endDate = parseISO(end);
      if (isSameDay(startDate, endDate)) {
        return `${formatDate(start, { date_format: "EEEE',' do MMM" })} 
        from ${formatDate(start, { date_format: "hh:mmaaa" })} 
        to ${formatDate(end, { date_format: "hh:mmaaa" })}`;
      }

      return `Start: ${formatDate(start, {
        date_format: "EEEE',' do MMM '|' hh:mmaaa",
      })}
      <br/> End: ${formatDate(end, {
        date_format: "EEEE',' do MMM '|' hh:mmaaa",
      })}`;
    },
  },
};
</script>
<style scoped>
.event_edit {
  position: absolute;
  right: 1em;
  top: 1em;
}
</style>
